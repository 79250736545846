import { useBreadcrumbs } from "@/js/Layouts/Public";
import { Ambassador, usePublicCampaignAmbassadors } from "@/js/resources";
import { createRequiredContext } from "@enymo/react-better-context";
import { ReturnSingle } from "@enymo/react-resource-hook";
import React, { useMemo, useState } from "react";
import { Outlet, useParams } from "react-router";

const [Provider, useAmbassadorProps] = createRequiredContext<{
    donationSuccessful: ["success" | undefined, React.Dispatch<React.SetStateAction<"success" | undefined>>],
    ambassador: [Ambassador | null, ReturnSingle<Ambassador, Ambassador>]
}>("DonationSuccessProvider has to be in the tree.");

export { useAmbassadorProps };

export default function DonationSuccessProvider() {
    const { campaignId, ambassadorId } = useParams(); 
    const donationSuccessful = useState<"success">();

    const [ambassador, actions] = usePublicCampaignAmbassadors({
        id: Number(ambassadorId),
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    useBreadcrumbs(ambassador ? [{
        relativePath: `ambassadors/${ambassadorId}`,
        text: ambassador?.name ?? "",
        index: 1,
    }] : [], [ambassadorId, ambassador?.name]);

    return (
        <Provider value={{
            donationSuccessful,
            ambassador: [ambassador, actions]
        }}>
            <Outlet />
        </Provider>
    )
}