import LeftIcon from "@/svg/chevron-left.svg?react";
import RightIcon from "@/svg/chevron-right.svg?react";
import React, { useEffect, useMemo, useState } from "react";
import SimpleBar from "simplebar-react";
import { toCurrency } from "../common";
import { Donation } from "../resources";
import IconButton from "./IconButton";


export default function DonationsTable({ 
    donations, 
    showEmail = false, 
    maxEntries = 10, 
    showAmbassadorName = false,
    showNotApproved = false,
}: {
    donations: Donation[],
    showEmail?: boolean,
    maxEntries?: number,
    showAmbassadorName?: boolean,
    showNotApproved?: boolean,
}) {
    const [page, setPage] = useState(0);
    const filteredDonations = useMemo(
        () => donations.sort((a, b) => b.created_at.getTime() - a.created_at.getTime())
            .filter(({approved}) => approved || showNotApproved), 
        [donations, showNotApproved]
    );
    const maxPages = useMemo(() => Math.ceil(filteredDonations.length / maxEntries), [filteredDonations, maxEntries]);
    useEffect(() => {
        if (page >= maxPages && maxPages > 0) {
            setPage(maxPages - 1);
        }
    }, [maxPages, setPage, page]);
    const donationsToDisplay = useMemo(() => filteredDonations.slice(page * maxEntries, (page + 1) * maxEntries), [filteredDonations, page, maxEntries]);
    return (
        filteredDonations.length > 0 && <SimpleBar className="rounded-lg max-w-full w-full overflow-x-auto">
            <table>
                <thead className="table-head">
                    <tr>
                        <th>Név</th>
                        {showEmail && <th>E-mail</th>}
                        {showAmbassadorName && <th>Támogatott nagykövet</th>}
                        <th>Megjegyzés</th>
                        <th>Összeg</th>
                        <th>Időpont</th>
                    </tr>
                </thead>
                <tbody>
                    {donationsToDisplay.map((donation) => (
                        <tr key={donation.id} className={`table-tr ${!(donation.approved) && "!bg-error-container"}`}>
                            <td>{donation.donor_name ?? "Anonim"}</td>
                            {showEmail && <td>{donation.donor_email}</td>}
                            {showAmbassadorName && <td>{donation.ambassador_name}</td>}
                            <td style={{
                                width: "100%",
                                minWidth: "200px",
                            }}>{donation.note}</td>
                            <td>{toCurrency(donation.amount)}</td>
                            <td>{donation.created_at.toLocaleDateString("hu")}</td>
                        </tr>
                    ))}
                </tbody>
                {maxPages !== 1 && (
                    <tfoot className="table-foot">
                        <tr>
                            <td colSpan={4 + (showEmail ? 1 : 0) + (showAmbassadorName ? 1 : 0)}>
                                <div className="flex justify-end items-center gap-2">
                                    <IconButton onClick={() => setPage(page - 1)} disabled={page === 0} variant="filled" compact>
                                        <LeftIcon />
                                    </IconButton>
                                    <span className="bd-s">{page + 1} / {maxPages}</span>
                                    <IconButton onClick={() => setPage(page + 1)} disabled={page === maxPages - 1} variant="filled" compact>
                                        <RightIcon />
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </SimpleBar>
    )
}