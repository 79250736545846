import { useAlert, useBreadcrumbs } from "@/js/Layouts/Public";
import { Campaign, usePublicCampaigns } from "@/js/resources";
import { createRequiredContext } from "@enymo/react-better-context";
import { ReturnSingle } from "@enymo/react-resource-hook";
import React from "react";
import { Outlet, useParams } from "react-router";

const [Provider, useCampaign] = createRequiredContext<[Campaign | null, ReturnSingle<Campaign, Campaign>]>("The Campaign must be provided to use the campaign.")

export { useCampaign };

export default function CampaignProvider() {
    const { campaignId } = useParams();
    const [campaign, actions] = usePublicCampaigns({
        id: Number(campaignId),
    })

    console.log(campaign)

    useBreadcrumbs(campaign ? [{
        relativePath: `campaigns/${campaignId}`,
        text: campaign?.name ?? "",
        index: 0,
    }] : [], [campaignId, campaign?.name]);

    
    useAlert((campaign?.active ?? true) ? null : {
        type: "error",
        title: "A kampány nem aktív.",
        message: "Így nem fogad adományokat."
    })

    return (
        <Provider value={[campaign, actions]}>
            <Outlet />
        </Provider>
    )
}