import { Row } from "@enymo/react-layout";
import React from "react";

export default function TableEmptyPlaceholder({
    colSpan = 3,
}: {
    colSpan?: number
}) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <Row gap="10px">
                    <span>Itt még nincs semmi...</span>
                </Row>
            </td>
        </tr>
    )
}