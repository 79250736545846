import "@/css/pages/app/organization.scss";
import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import { ImageInput } from "@/js/Components/ImageInput";
import Loader from "@/js/Components/Loader";
import PropertyForm from "@/js/Components/PropertyForm";
import TableEmptyPlaceholder from "@/js/Components/TableEmptyPlaceholder";
import TableLoadingPlaceholder from "@/js/Components/TableLoadingPlaceholder";
import useMeasuring from "@/js/Hooks/MeasuringHook";
import { useOrganization } from "@/js/Layouts/App";
import { ORGANIZATION_IMAGE_ASPECT_RATIO } from "@/js/common";
import { ApiInvitationToken, invitationTokenTransformer, useOrganizationInvitationTokens, useOrganizationUsers } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { route } from "ziggy-js";

interface OrganizationSubmit {
    name: string,
    email: string,
    website: string,
    facebook: string,
    instagram: string,
}

interface UserInvitationSubmit {
    emails: string,
}

export default function Organization() {

    const [pageRef, { width, height }] = useMeasuring<HTMLDivElement>();

    const [organization, { update: updateOrganization }] = useOrganization();
    const [users, { loading: usersLoading }] = useOrganizationUsers({
        params: useMemo(() => ({
            organization: organization?.id,
        }), [organization]),
        autoRefresh: !!organization,
    });
    const [invitationTokens, { loading: invitationTokensLoading, destroy: destroyInviationToken, store: storeInvitationToken }] = useOrganizationInvitationTokens({
        params: useMemo(() => ({
            organization: organization?.id,
        }), [organization]),
        autoRefresh: !!organization,
    });

    const imageWidth = useMemo(() => width < 800 ? width : 200, [width]);

    const [editMode, setEditMode] = useState(false);
    const form = useForm<OrganizationSubmit>();
    const userInvitationForm = useForm<UserInvitationSubmit>();

    useEffect(() => {
        if (!organization) return;
        setFormValues(form, {
            email: organization.email,
            name: organization.name,
            website: organization.website,
            facebook: organization.facebook,
            instagram: organization.instagram,
        });
    }, [organization]);

    const handleSubmit: SubmitHandler<OrganizationSubmit> = async (data) => {
        await updateOrganization(data);
        setEditMode(false);
    }

    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

    const handleFileSelect = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        updateOrganization({
            file,
        }, 'immediate', {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress ?? 0) * 100;
                if (progress < 100) {
                    setUploadProgress(progress);
                }
                else {
                    setUploadProgress(undefined);
                }
            }
        });
    }

    const handleDeleteImage = () => {
        updateOrganization({
            file: null,
            has_image: false,
            image_position_ratio: null,
        }, 'immediate');
    }

    const handleUserInvitationSubmit: SubmitHandler<UserInvitationSubmit> = async (data) => {
        const tokens = (await axios.post<ApiInvitationToken[]>(route("organizations.invite-users", { organization: organization!.id}), {
            emails: data.emails.split(",").map(email => email.trim()),
        })).data;

        tokens.forEach((token) => storeInvitationToken(invitationTokenTransformer(token), 'local-only'));
        userInvitationForm.reset();
    }

    console.log(organization);

    return (
        <div className="flex flex-col gap-8 py-10 max-w-[1200px] w-full self-center" ref={pageRef}>
            {!organization ? (
                <div className="flex flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            ) : (
                <>
                    <div className="flex flex-col gap-8">
                        <div className={`flex flex-col grow gap-10 sm:flex-row`}>
                            <ImageInput
                                imageHolder={{ ...organization, source: "organizations" }}
                                uploadProgress={uploadProgress}
                                handleFileSelect={handleFileSelect}
                                onDelete={handleDeleteImage}
                                onEdit={(position) => updateOrganization({
                                    image_position_ratio: position,
                                })}
                                aspectRatio={ORGANIZATION_IMAGE_ASPECT_RATIO}
                                className="w-full sm:w-[300px]"
                            />
                            <Form form={form} onSubmit={handleSubmit} className="flex-1">
                                <div className="flex flex-col gap-3 flex-1">
                                    <PropertyForm
                                        editable={editMode}
                                        properties={[
                                            { 
                                                label: "Név", 
                                                value: organization.name,
                                                input: {
                                                    name: "name",
                                                    label: "{useLabel}",
                                                    options: { required : "Kötelező mező" },
                                                },
                                            },
                                            { 
                                                label: "E-mail", 
                                                value: organization.email,
                                                input: {
                                                    name: "email",
                                                    label: "{useLabel}",
                                                    options: { required : "Kötelező mező" },
                                                },
                                            },
                                            { 
                                                label: "Weboldal", 
                                                value: organization.website,
                                                input: {
                                                    name: "website",
                                                    label: "{useLabel}",
                                                },
                                            },
                                            { 
                                                label: "Facebook", 
                                                value: organization.facebook,
                                                input: {
                                                    name: "facebook",
                                                    label: "{useLabel}",
                                                },
                                            },
                                            { 
                                                label: "Instagram", 
                                                value: organization.instagram,
                                                input: {
                                                    name: "instagram",
                                                    label: "{useLabel}",
                                                },
                                            },
                                        ]}
                                        mobile={width < 800}
                                    />
                                    <div className="flex justify-end gap-3">
                                        <Button variant="outline" onClick={() => setEditMode(!editMode)}>
                                            {editMode ? "Mégse" : "Szerkesztés"}
                                        </Button>
                                        {editMode && (
                                            <Button variant="filled-tonal" submit>Mentés</Button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <h2 className="ttl-l">Kezelők hozzáadása</h2>
                        <span style={{
                            color: "var(--on-surface)",
                            fontSize: "12px"
                        }}>Add meg az e-maileket vesszővel elválasztva</span>
                        <Form form={userInvitationForm} onSubmit={handleUserInvitationSubmit}>
                            <div className="flex flex-col gap-3">
                                <Input type="textarea" name="emails" inputClassName="h-[200px]"/>
                                <div className="flex justify-end">
                                    <Button variant="filled-tonal" submit>Hozzáadás</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l">A szervezet kezelői</h2>
                        <table>
                            <thead className="table-head">
                                <tr>
                                    <th>Név</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersLoading ? (
                                    <TableLoadingPlaceholder colSpan={2} />
                                ) : users?.map(user => (
                                    <tr key={user.id} className="table-tr">
                                        <td>{`${user.last_name} ${user.first_name}`}</td>
                                        <td>{user.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l">Függőben lévő meghívók</h2>
                        <table>
                            <thead className="table-head">
                                <tr>
                                    <th>E-mail</th>
                                    <th>Érvényesség</th>
                                    <th>Törlés</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invitationTokensLoading ? (
                                    <TableLoadingPlaceholder colSpan={3} />
                                ) : invitationTokens?.length > 0 ? invitationTokens?.map(token => (
                                    <tr key={token.id} className="table-tr">
                                        <td>{token.email}</td>
                                        <td>{token.valid_until.toLocaleDateString('hu') + " " + token.valid_until.toLocaleTimeString('hu')}</td>
                                        <td>
                                            <Button variant="outline" onClick={() => destroyInviationToken(token.id)}>
                                                Törlés
                                            </Button>
                                        </td>
                                    </tr>
                                )) : (
                                    <TableEmptyPlaceholder />
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    )
}