import { useGlissadeButton } from "@enymo/glissade";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import React from "react";
import Loader from "./Loader";

export default function IconButton({ 
    children,
    onClick: onClickProp,
    loading: loadingProp,
    disabled: disabledProp,
    submit,
    variant = "standard",
    compact = false,
    className,
    ...props
}: {
    children: React.ReactNode,
    variant?: "standard" | "filled" | "filled-tonal" | "outlined"
    loading?: boolean;
    compact?: boolean;
    inline?: boolean;
} & Omit<ClickableProps, "children">) {

    const { disabled, loading, onClick} = useGlissadeButton({ 
        onClick: onClickProp, 
        disabled: disabledProp, 
        loading: loadingProp, 
        submit 
    });

    return (
        <Clickable
            className={`
                flex items-center justify-center rounded-full [&_svg]:size-5
                ${className ?? ""}
                ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                ${variant === "standard" && "interactive-bg-surface [&_svg]:fill-on-surface"}
                ${variant === "filled" && "interactive-bg-primary [&_svg]:fill-on-primary"}
                ${variant === "filled-tonal" && "interactive-bg-secondary-container [&_svg]:fill-on-secondary-container"}
                ${variant === "outlined" && "interactive-bg-surface border border-outline [&_svg]:fill-on-surface"}
                ${compact ? "h-5 w-8 [&_svg]:size-4" : "size-10"}
            `}
            onClick={onClick} 
            disabled={disabled} 
            {...props}
        >
            {!loading && children}
            {loading && <Loader />}
        </Clickable>
    )
}