import React from "react";

export default function StaticProgressBar({ 
    progress,
    variant,
}: {
    progress: number,
    variant: "primary" | "secondary" | "tertiary",
}) {
    return (
        <div className={`
            relative h-5 rounded-xl w-full outline-2 outline overflow-hidden bg-surface-container-high
            ${variant === "primary" && "outline-primary"}
            ${variant === "secondary" && "outline-secondary"}
            ${variant === "tertiary" && "outline-tertiary"}
        `}>
            <div className={`
                absolute top-[-1px] left-[-1px] h-[calc(100%+2px)] rounded-lg transition-[width]
                ${variant === "primary" && "bg-primary"}
                ${variant === "secondary" && "bg-secondary"}
                ${variant === "tertiary" && "bg-tertiary"}
            `} style={{ width: progress === 0 ? '0' : `min(calc(${progress}% + 1px), calc(100% + 1px))` }} />
        </div>
    )
}