import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import { useUser } from "@/js/Providers/UserProvider";
import { User } from "@/js/resources";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column } from "@enymo/react-layout";
import { requireNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import { route } from "ziggy-js";

interface Submit {
    password: string;
    password_confirmation: string;
    first_name: string;
    last_name: string;
}

export default function Invitation() {

    const [searchParams] = useSearchParams();

    const token = searchParams.get('token');
    const form = useForm<Submit>();
    const { update } = requireNotNull(useUser());

    if (!token) {
        return <Navigate to="/login" />
    }

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        const user = (await axios.post<User>(route("users.accept-invitation"), {
            ...data,
            token,
        })).data;

        update(user, 'local-only');
    }

    return (
        <Column className="bg-surface-container p-10 rounded-2xl" gap="40px" maxWidth="500px" width="100%">
            <h2 className="hd-s">Meghívó elfogadása</h2>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="30px" padding="0 5px">
                    <Column gap="15px">
                    <Input label="Vezetéknév" name="last_name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Keresztnév" name="first_name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Jelszó" name="password" type="password" options={{
                        required: "Kötelező mező",
                        minLength: {
                            value: 8,
                            message: "A jelszónak legalább 8 karakter hosszúnak kell lennie"
                        }
                    }} />
                    <Input label="Jelszó megerősítése" name="password_confirmation" type="password" options={{
                        required: "Kötelező mező",
                        validate: (value) => value === form.getValues().password || "A két jelszó nem egyezik"
                    }} />
                    </Column>
                    <div className="flex justify-center">
                        <Button variant="filled" submit>Regisztráció</Button>
                    </div>
                </Column>
            </Form>
        </Column>
    )
}