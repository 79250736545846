import ChevronRight from "@/svg/chevron-right.svg?react";
import FacebookIcon from "@/svg/facebook.svg?react";
import InstragramIcon from "@/svg/instagram.svg?react";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import { Row } from "@enymo/react-layout";
import { ReturnSingle } from "@enymo/react-resource-hook";
import useScreenSize from "@enymo/react-screen-size-hook";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Image } from "../Components/Image";
import NavbarButton from "../Components/NavbarButton";
import { ORGANIZATION_IMAGE_ASPECT_RATIO } from "../common";
import { Organization, usePublicOrganizations } from "../resources";

interface AlertProps {
    type: "error" | "success";
    title?: string;
    message?: string;
}

function Alert({ type, title, message }: AlertProps) {
    return (
        <div className={`
            flex flex-col items-center gap-3 p-3
            ${type === "error" && "bg-error-container"}
        `}>
            {title && <h3 className="ttl-l">{title}</h3>}
            {message && <p className="bd-m">{message}</p>}
        </div>
    )
}
interface BreadcumbProps {
    relativePath: string;
    text: string;
    index: number;
}

export function Breadcrumb({ to, text, showChevron = true }: {
    to: string;
    text: string;
    showChevron?: boolean;
}) {

    return (
        <Row className="breadcrumb">
            {showChevron && <ChevronRight />}
            <Link to={to}>
                <span>{text}</span>
            </Link>
        </Row>
    )
}

function BreadcrumbAssembler({ breadcrumbs: breadcrumbsProp }: {
    breadcrumbs: BreadcumbProps[];
}) {
    const { organizationId } = useParams();
    const { width } = useScreenSize();

    const breadcrumbs = useMemo(() => breadcrumbsProp.sort((a, b) => a.index - b.index), [breadcrumbsProp]);
    const paths = useMemo(() => {
        const paths: string[] = [];
        return breadcrumbs.map(({ relativePath }) => {
            paths.push(relativePath);
            return paths.join("/");
        })
    }, [breadcrumbs]);

    return (
        <Row className={classNames("breadcrumbs", { mobile: width < 700 })}>
            {breadcrumbs.map(({ relativePath, text }, index) => (
                <Breadcrumb key={relativePath} to={`/public/organizations/${organizationId}/${paths[index]}`} text={text} />
            ))}
        </Row>
    )
}
const [OrganizationProvider, useOrganization] = createRequiredContext<[Organization | null, ReturnSingle<Organization, Organization>]>("The OrganizationProvider must be present in the `public` component tree.");
const [BreadcrumbProvider, useSetBreadcrumbs] = createRequiredContext<React.Dispatch<React.SetStateAction<BreadcumbProps[]>>>("The BreadcumbProvider must be provided to useBreadcrumbs");
const [AlertProvider, useSetAlert] = createRequiredContext<React.Dispatch<React.SetStateAction<AlertProps | null>>>("The AlertProvider must be provided to useAlert");

const useBreadcrumbs = (breadcrumbs: BreadcumbProps[], dependencies?: React.DependencyList) => {
    const setBreadcrumbs = useSetBreadcrumbs();
    useEffect(() => {
        setBreadcrumbs(oldBreadCrumbs => [...oldBreadCrumbs, ...breadcrumbs]);
        return () => setBreadcrumbs(oldBreadcrumbs => oldBreadcrumbs.filter(breadcumb => !breadcrumbs.includes(breadcumb)));
    }, [setBreadcrumbs, dependencies]);
}

const useAlert = (alertProps: AlertProps | null) => {
    const setAlertProps = useSetAlert();
    useEffect(() => {
        setAlertProps(alertProps);
        return () => setAlertProps(null);
    }, [alertProps, setAlertProps]);
}


export { useAlert, useBreadcrumbs, useOrganization };


export default function Public() {
    const { organizationId } = useParams();
    const [organization, actions] = usePublicOrganizations({
        id: Number(organizationId),
    })

    const [breadcrumbs, setBreadcrumbs] = useState<BreadcumbProps[]>([]);
    const { width } = useScreenSize();
    const mobile = width < 700;


    const [alertProps, setAlertProps] = useState<AlertProps | null>(null);

    return (
        <OrganizationProvider value={[organization, actions]}>
            <div className="flex flex-col flex-1 bg-surface">
                <div className="bg-voneszo px-4 py-3 shadow-lg shrink-0 relative z-10 gap-2 items-center">
                    <div className="flex justify-between items-center">
                        {organization ? (
                            <Image
                                className="!rounded-[15px] h-[50px] bg-white sm:h-[80px]"
                                imageHolder={{ ...organization, source: "organizations" }}
                                aspectRatio={ORGANIZATION_IMAGE_ASPECT_RATIO}
                                axisSet="y"
                            />
                        ) : (
                            <div className="h-[50px] sm:h-[80px]" />
                        )}
                        <div className="flex gap-3 items-center justify-between sm:justify-normal">
                            <NavbarButton to={organization?.website} linkType="new-tab" className="interactive-bg-surface-light">
                                <div className="flex gap1 items-center gap-1">
                                    <span>Honlapunk</span>
                                    <OpenInNewIcon className="h-[15px] fill-on-surface-light" />
                                </div>
                            </NavbarButton>
                            {organization?.facebook && (
                                <Link 
                                    className="flex items-center justify-center interactive-bg-surface-light rounded-full p-[5px] shrink-0 size-8"
                                    to={organization.facebook} 
                                    target="_blank"
                                >
                                    <FacebookIcon className="fill-on-surface-light" />
                                </Link>
                            )}
                            {organization?.instagram && (
                                <Link
                                    to={organization.instagram}
                                    className="flex items-center justify-center interactive-bg-surface-light rounded-full p-[5px] shrink-0 size-8"
                                    target="_blank"
                                >
                                    <InstragramIcon className="fill-on-surface-light" />
                                </Link>
                            )}
                        </div>
                    </div>
                    {/* <Row align="space">
                        <BreadcrumbAssembler breadcrumbs={breadcrumbs} />
                    </Row> */}
                </div>
                <BreadcrumbProvider value={setBreadcrumbs}>
                    <AlertProvider value={setAlertProps}>
                        <SimpleBar style={{ height: "100%" }}>
                            {alertProps && <Alert {...alertProps} />}
                            <main className="flex flex-col flex-1 h-full w-full px-5 sm:px-10">
                                <Outlet />
                            </main>
                            {/* <footer>
                                Itt majd lesz valami
                            </footer> */}
                        </SimpleBar>
                    </AlertProvider>
                </BreadcrumbProvider>
            </div>
        </OrganizationProvider>
    );
}