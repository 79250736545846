import "@/css/glidespec.scss";
import Loader from "@/js/Components/Loader";
import CheckIcon from "@/svg/check.svg?react";
import SelectIcon from "@/svg/chevron-down.svg?react";
import EyeSlashIcon from "@/svg/eye-slash.svg?react";
import EyeIcon from "@/svg/eye.svg?react";
import { createGlideButton, createGlideChoice, createGlideInput } from "@enymo/glide";
import classNames from "classnames";
import React, { useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailRegex, addOpacity, mixColors } from "./common";

export const Button = createGlideButton({
    style: {
        fontWeight: 500,
        fontFamily: "'Noto Sans', sans-serif",
    },
    variants: {
        ".common" : {
            style: {
                height: "40px",
            }
        },
        ".filled": {
            extends: ".common",
            style: {
                padding: "0 24px",
                borderRadius: "20px",
            },
        },
        primary: {
            extends: ".filled",
            style: {
                background: "var(--primary)",
                fontSize: "14px",
                color: "var(--on-primary)",
                fill: "var(--on-primary)"
            },
            hoverStyle: {
                background: mixColors("primary", "on-primary", 8),
            },
            clickStyle: {
                background: mixColors("primary", "on-primary", 10),
            },
            disabledStyle: {
                background: addOpacity("on-surface", 0.12),
                color: addOpacity("on-surface", 0.38),
                fill: addOpacity("on-surface", 0.38),
            }
        },
        secondary: {
            extends: ".filled",
            style: {
                background: "var(--secondary)",
                fontSize: "14px",
                color: "var(--on-secondary)",
                fill: "var(--on-secondary)"
            },
            hoverStyle: {
                background: mixColors("secondary", "on-secondary", 8),
            },
            clickStyle: {
                background: mixColors("secondary", "on-secondary", 10),
            },
            disabledStyle: {
                background: addOpacity("on-surface", 0.12),
                color: addOpacity("on-surface", 0.38),
                fill: addOpacity("on-surface", 0.38),
            },
        },
        outline: {
            style: {
                padding: "0 24px",
                borderRadius: "20px",
                border: "1px solid var(--outline)",
                color: "var(--primary)",
                fill: "var(--primary)",
                height: "40px",
            },
            disabledStyle: {
                color: addOpacity("on-surface", 0.38),
                outlineColor: addOpacity("on-surface", 0.12),
            },
            hoverStyle: {
                background: addOpacity("primary", 0.08),
            },
            clickStyle: {
                background: addOpacity("primary", 0.1),
            },
            
        },
        text: {
            style: {
                height: "40px",
                color: "var(--primary)",
                fill: "var(--primary)",
                fontSize: "14px",
                padding: "0 12px",
                borderRadius: "20px",
            },
            disabledStyle: {
                color: addOpacity("on-surface", 0.38),
            },
            hoverStyle: {
                backgroundColor: addOpacity("primary", 0.08),
            },
            clickStyle: {
                backgroundColor: addOpacity("primary", 0.1),
            },
        }
    },
    defaultVariant: "secondary",
    loader: <Loader style={{maxWidth: "100%", maxHeight: "100%"}} />,
    loaderPadding: "5px"
});

export type ButtonProps = typeof Button extends (props: infer P) => unknown ? P : never;

export const Input = createGlideInput({
    labelPosition: "outside-top",
    labelStyle: {
        fontWeight: 500,
        fontSize: "15px",
        color: "var(--on-surface-variant)"
    },
    labelGap: "7px",
    style: {
        height: "50px",
        backgroundColor: "var(--surface)",
        border: "1px solid var(--outline-variant)",
        borderRadius: "12px",
        color: "var(--on-surface)",
        fontSize: "15px",
    },
    hoverStyle: {
        borderColor: "var(--on-surface)"
    },
    focusStyle: {
        borderColor: "var(--primary)"
    },
    errorStyle: {
        borderColor: "var(--error)"
    },
    disabledStyle: {
        borderColor: "var(--on-surface)",
        backgroundColor: addOpacity("on-surface", 0.04),
    },
    placeholderStyle: {
        color: "var(--text-100)"
    },
    inputPadding: "0 16px",
    suffix: ({suffix}: {
        suffix?: React.ReactNode
    }) => suffix && (
        <div className="suffix">
            {suffix}
        </div>
    ),
    prefix: ({prefix}: {
        prefix?: React.ReactNode
    }) => prefix && (
        <div className="prefix">
            {prefix}
        </div>
    ),
    errorGap: "4px",
    errorTextStyle: {
        fontSize: "14px",
        color: "var(--error)"
    },
    selectIndicator: ({className}: {
        className?: string,
    }) => (
        <div className={classNames("select-indicator", className)}>
            <SelectIcon height="15px" width="30px" />
        </div>
    )
});
type InputProps = typeof Input extends (props: infer P) => unknown ? P : never;

export function EmailInput({options, label, name = "email", ...props}: Omit<InputProps, "type">) {
    const {t} = useTranslation();

    return (
        <Input name={name} type="email" label={label ?? "E-Mail"} options={{
            ...options,
            pattern: {
                value: EmailRegex,
                message: "Nem érvényes email cím"
            }
        } as RegisterOptions} {...props} />
    )
}

export function PasswordInput({className, ...props}: Omit<InputProps, "type" | "icon">) {
    const [visible, setVisible] = useState(false);

    return (
        <Input
            className={classNames("password-input", className)}
            type={visible ? "text" : "password"}
            suffix={
                <button type="button" onClick={() => setVisible(!visible)}>
                    {visible ? <EyeSlashIcon className="eye-slash" /> : <EyeIcon className="eye" />}
                </button>
            }
            {...props}
        />
    )
}

export const Checkbox = createGlideChoice({
    indicator: {
        style: {
            width: "26px",
            height: "26px",
            border: "1px solid rgb(var(--color-outline-variant))",
            borderRadius: "8px",
            background: "rgb(var(--color-surface))"
        },
        selectedStyle: {
            borderColor: "rgb(var(--color-primary))",
            background: "rgb(var(--color-primary))"
        },
        element: <CheckIcon width="18px" fill="rgb(var(--color-on-primary))" />
    },
    childrenGap: "10px",
    labelStyle: {
        margin: "5px 0",
        fontSize: "14px",
        color: "rgb(var(--color-on-surface-variant))"
    },
    wrapperStyle: {
        cursor: "pointer"
    },
})