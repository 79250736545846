import { isNotNull } from "@enymo/ts-nullsafe";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";

export type FileInputRef = React.ElementRef<typeof FileInput>

const FileInput = forwardRef<{
    open(): void
}, {
    onSelected(files: File[]): void,
    accept?: string,
    multiple?: boolean
}>(({
    onSelected,
    ...props
}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            console.log('asas', inputRef.current?.size)
            inputRef.current?.click()
        }
    }), [inputRef]);

    const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
        if (isNotNull(e.target.files)) {
            onSelected([...e.target.files]);
        }
        e.target.value = "";
    }, [onSelected])

    return (
        <input
            ref={inputRef}
            type="file"
            style={{display: "none"}}
            onChange={handleChange}
            {...props}
        />
    )
});
export default FileInput;