
import "@/css/app.scss";
import { requireNotNull } from "@enymo/ts-nullsafe";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import { createRoot } from "react-dom/client";
import { initReactI18next } from "react-i18next";
import Bootstrapper from "./Bootstrapper";

import 'simplebar-react/dist/simplebar.min.css';

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            convertDetectedLanguage: lng => lng.split("-")[0]
        },
        debug: import.meta.env.VITE_I18N_DEBUG === "true",
        fallbackLng: "en",
        resources: {},
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });

document.addEventListener("wheel", function(event){ // Prevents scrolling on number inputs
    if(document.activeElement && (document.activeElement as HTMLInputElement).type === "number"){
        (document.activeElement as HTMLInputElement).blur();
    }
});


createRoot(requireNotNull(document.getElementById("app"))).render(<Bootstrapper />);