import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { Navigate, Outlet } from "react-router";
import { useUser } from "./Providers/UserProvider";

export default function Gate({realm}: {
    realm: "login" | "app" | "public"
}) {
    const userData = useUser();

    if (isNotNull(userData) && userData.loading) {
        return null;
    }

    if (realm === "public") {
        return <Outlet />
    }

    assertNotNull(userData, "If the realm is not public, the user data must be loaded");
    const { user } = userData;

    if (user === null && realm !== "login") {
        return <Navigate to="/login" replace />
    }
    else if (user !== null && realm !== "app") {
        return <Navigate to ="/app" replace />
    }
    else {
        return <Outlet />
    }
}