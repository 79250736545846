import React, { useMemo, useState } from "react";

export default function CutText({text, maxLength, style, ...props}: {
    text: string,
    maxLength: number
} & React.HTMLAttributes<HTMLSpanElement>) {
    
    const [showWholeText, setShowWholeText] = useState(false);

    const textToShow = useMemo(() => {
        return showWholeText ? text : text.slice(0, maxLength).trimEnd() + (text.length > maxLength ? "... " : "");
    }, [text, maxLength, showWholeText]);
    
    return (
        <span style={{
            ...style,
            whiteSpace: "pre-wrap",
            flex: 1
        }} {...props}>
            {textToShow}
            {text.length > maxLength && (
                <button 
                    className="inline interactive-bg-secondary-container px-1 rounded-md"
                    onClick={() => setShowWholeText(!showWholeText)} 
                >
                    {showWholeText ? "Kevesebb" : "Több"}
                </button>
            )}
        </span>
    )
}