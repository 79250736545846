import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import PropertyForm from "@/js/Components/PropertyForm";
import { useUser } from "@/js/Providers/UserProvider";
import { Form, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import useScreenSize from "@enymo/react-screen-size-hook";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface UserSettingsForm {
    first_name: string;
    last_name: string;
    email: string;
}

interface PasswordForm {
    old_password: string;
    password: string;
    password_confirmation: string;
}

export default function UserSettings() {
    const { user, update } = requireNotNull(useUser());
    assertNotNull(user);

    useEffect(() => {
        setFormValues(form, user);
    }, [user]);

    const { width } = useScreenSize();
    const mobile = width < 700;

    const [editable, setEditable] = useState(false);

    const form = useForm<UserSettingsForm>();
    const passwordForm = useForm<PasswordForm>();

    const handleSubmit: SubmitHandler<UserSettingsForm> = async (data) => {
        await update(data);
        setEditable(false);
    };

    const handleSubmitPassword: SubmitHandler<PasswordForm> = async (data) => {
        await update(data);
    }

    return (
        <div className="flex flex-col flex-1 gap-8 py-10 w-full max-w-[800px] self-center">
            <h1 className="ttl-l">Felhasználói adatok</h1>
            <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-5">
                <PropertyForm
                    editable={editable}
                    properties={[
                        {
                            label: "Vezetéknév",
                            value: user.last_name,
                            input: {
                                name: "last_name",
                                options: { required: "Kötelező mező" },
                            }
                        },
                        {
                            label: "Kersztnév",
                            value: user.first_name,
                            input: {
                                name: "first_name",
                                options: { required: "Kötelező mező" },
                            }
                        },
                        {
                            label: "E-mail cím",
                            value: user.email,
                            input: {
                                name: "email",
                                options: { required: "Kötelező mező" },
                            }
                        },
                    ]}
                />
                <div className="flex justify-end gap-3">
                    <Button
                        variant="outline"
                        onClick={() => setEditable(!editable)}
                    >
                        {editable ? "Mégse" : "Szerkesztés"}
                    </Button>
                    {editable && (
                        <Button variant="filled-tonal" submit>
                            Mentés
                        </Button>
                    )}
                </div>
            </Form>
            <h1 className="ttl-l">Jelszó módosítása</h1>
            <Form form={passwordForm} onSubmit={handleSubmitPassword} className="flex flex-col gap-5">
                    <Input
                        label="Korábbi jelszó"
                        name="old_password" 
                        type="password" 
                        options={{ required: "Kötelező mező" }} 
                    />
                    <Input
                        label="Új jelszó"
                        name="password" 
                        type="password" 
                        options={{ required: "Kötelező mező" }}
                    />
                    <Input
                        label="Új jelszó megerősítése"
                        name="password_confirmation" 
                        type="password" 
                        options={{ required: "Kötelező mező", validate: (value, values) => value === values.password || "A két jelszó nem egyezik." }}
                    />
                    <div className="flex justify-end">
                        <Button variant="filled-tonal" submit>Mentés</Button>
                    </div>
            </Form>
        </div>
    );
}
