import "@/css/layouts/app.scss";
import AccountCircle from "@/svg/account-circle.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { ReturnSingle } from "@enymo/react-resource-hook";
import useScreenSize from "@enymo/react-screen-size-hook";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import NavbarButton from "../Components/NavbarButton";
import { useUser } from "../Providers/UserProvider";
import { Organization, useOrganizations } from "../resources";

const [OrganizationProvider, useOrganization] = createRequiredContext<[Organization | null, ReturnSingle<Organization, Organization>]>("The OrganizationProvider must be present in the `app` component tree.");
const [PaddingProvider, useMainPadding] = createRequiredContext<number>("The PaddingProvider is required in the main app context.");

export { useMainPadding, useOrganization };

export default function App() {
    const { logout, user } = requireNotNull(useUser());
    assertNotNull(user);
    const organizationResource = useOrganizations({ id: user.organization_id });
    const organization = organizationResource[0];
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const profileDropdownRef = useOnClickOutside<HTMLDivElement>(() => setShowAccountMenu(false), [setShowAccountMenu]);
    const mobile = useScreenSize().width < 700;


    const padding = useMemo(() => mobile ? 20 : 40, [mobile]);

    return (
        <PaddingProvider value={padding}>
            <OrganizationProvider value={organizationResource}>
                <div className="flex flex-col flex-1 bg-surface">
                    <div className="flex items-center justify-between h-[50px] shrink-0 px-[20px] relative z-20 bg-voneszo">
                        <h1 className="text-on-primary text-[22px] font-medium font-noto text-ellipsis whitespace-nowrap overflow-hidden">{organization?.name}</h1>
                        <div className="flex gap-[10px] items-center">
                            <NavbarButton to="/app/campaigns">Kampányok</NavbarButton>
                            <NavbarButton to="/app/organization">Szervezet</NavbarButton>
                            <div ref={profileDropdownRef} className="flex z-20">
                                <button className={`h-[30px] interactive-bg-surface rounded-full ${showAccountMenu && "interactive-bg-secondary-container"}`} onClick={() => setShowAccountMenu(!showAccountMenu)}>
                                    <AccountCircle className={`h-[30px] fill-on-surface ${showAccountMenu && "fill-on-secondary-container"}`} />
                                </button>
                                {showAccountMenu && (
                                    <div className="flex flex-col absolute z-20 top-[calc(100%-10px)] right-[20px] bg-surface-container py-[10px] rounded-[5px] shadow-md">
                                        <Link className="interactive-bg-surface-container px-[10px] py-[5px]" to="/app/user-settings">Beállítások</Link>
                                        <button className="text-error hover:bg-error-container px-[10px] py-[5px]" onClick={logout}>Kijelentkezés</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center shrink-0 bg-error-container py-1 z-10 shadow-xl">
                        <h2>Szerkesztői felület</h2>
                    </div>
                    <SimpleBar style={{ height: "100%" }}>
                        <main className="px-5 sm:px-10">
                            <div className="flex flex-col justify-center" >
                                <Outlet />
                            </div>
                        </main>
                    </SimpleBar>
                </div>
            </OrganizationProvider>
        </PaddingProvider>
    )
}