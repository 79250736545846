import EditOffIcon from "@/svg/edit-off.svg?react";
import EditIcon from "@/svg/edit.svg?react";
import SaveIcon from "@/svg/save.svg?react";
import DeleteIcon from "@/svg/trash.svg?react";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { route } from "ziggy-js";
import useMeasuring from "../Hooks/MeasuringHook";
import { useObjectUrl } from "../Hooks/ObjectUrlHook";
import Loader from "./Loader";

export interface PositionRatio {
    x: number,
    y: number,
}
interface CommonImageProps extends Omit<ClickableProps, "loading" | "children"> {
    loading?: boolean,
    uploadProgress?: number,
    onDelete?: () => void,
    onEdit?: (positionRatio: {
        x: number,
        y: number,
    }) => void | Promise<void>,
    positionRatio?: PositionRatio,
    editable?: boolean,
    aspectRatio: number,
    axisSet?: "x" | "y",
}

interface ResourceImageProps extends CommonImageProps {
    imageHolder: {
        id: number,
        file?: File | null,
        has_image: boolean,
        source: string,
        image_position_ratio: PositionRatio | null,
    },
}

interface ImageProps extends CommonImageProps {
    image: File | null,
}

interface CombinedImageProps extends CommonImageProps {
    imageHolder?: {
        id: number,
        file?: File | null,
        has_image: boolean,
        source: string,
        image_position_ratio: PositionRatio | null,
    },
    image?: File | null,
}


interface Offset {
    x: number,
    y: number,
}

export function Image(props: ResourceImageProps): JSX.Element;
export function Image(props: ImageProps): JSX.Element;

export function Image({
    image,
    imageHolder,
    loading: loadingProp,
    className,
    uploadProgress,
    onDelete,
    onEdit,
    positionRatio: positionRatioProp,
    aspectRatio,
    axisSet = "x",
    style,
    editable = false,
    ...props
}: CombinedImageProps) {
    const [loaded, setLoaded] = useState<string>();

    const resourceSrc = useObjectUrl(imageHolder?.file) ?? (imageHolder?.has_image ? route(`${imageHolder.source}.image`, { id: imageHolder?.id }) : null);
    const positionRatio = positionRatioProp ?? imageHolder?.image_position_ratio;
    const imageSrc = useObjectUrl(image);
    const src = imageSrc ?? resourceSrc;
    const loading = loadingProp ?? (src !== loaded && src !== null);

    const handleLoad = useCallback<React.ReactEventHandler<HTMLVideoElement | HTMLImageElement>>((e) => {
        setLoaded(e.currentTarget.src);
    }, [setLoaded]);

    const [editMode, setEditMode] = useState(false);
    const [imageMoving, setImageMoving] = useState(false);
    // The size of the rendered img element
    const [imageSize, setImageSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
    // The ref and computed size of the container surrounding the img element
    const [containerRef, { width: renderedWidth, height: renderedHeight }] = useMeasuring<HTMLDivElement>();
    // The ref for the img element, used to get the natural size of the image
    const imageRef = useRef<HTMLImageElement>(null);
    // The width and height with aspect ratio applied to the container
    const [width, height] = useMemo(() => {
        if (aspectRatio === 0) {
            return [renderedWidth, renderedHeight];
        }
        if (axisSet === "x") {
            return [renderedWidth, renderedWidth / aspectRatio];
        }
        return [renderedHeight * aspectRatio, renderedHeight];
    }, [renderedWidth, renderedHeight, aspectRatio, axisSet]);
    const [offset, setOffset] = useState<Offset>({ x: 0, y: 0 });
    const displayOffset = useMemo(() => ({
        x: Math.max(Math.min(0, offset.x), width - imageSize.width),
        y: Math.max(Math.min(0, offset.y), height - imageSize.height),
    }), [offset, width, height, imageSize]);

    useEffect(() => {
        if (
            width === 0 || height === 0 ||
            !imageRef.current || 
            imageRef.current.naturalWidth === 0 || 
            (imageSize.width !== 0 && (imageSize.width === width || imageSize.height === height))
        ) {
            return;
        }
        const widthRatio = imageRef.current.naturalWidth / width;
        const heightRatio = imageRef.current.naturalHeight / height;
        const newWidth = imageRef.current.naturalWidth / heightRatio;
        const newHeight = imageRef.current.naturalHeight / widthRatio;
        if (widthRatio > heightRatio) {
            setImageSize({
                width: newWidth,
                height,
            });
        }
        else {
            setImageSize({
                width,
                height: newHeight,
            });
        }

        setOffset({
            x: newWidth * (positionRatio?.x ?? 0),
            y: newHeight * (positionRatio?.y ?? 0),
        })

    }, [imageRef, height, width, setOffset, setImageSize, positionRatio, imageSize, loaded]);

    const [saveLoading, setSaveLoading] = useState(false);

    const handleSave = async () => {
        setSaveLoading(true);
        await onEdit?.({
            x: displayOffset.x / imageSize.width,
            y: displayOffset.y / imageSize.height,
        });
        setSaveLoading(false);
        setEditMode(false);
    }

    const handleToggleEditMode = () => {
        if (!editMode) {
            setEditMode(true);
        }
        else {
            setEditMode(false);
            setOffset({
                x: (positionRatio?.x ?? 0) * imageSize.width,
                y: (positionRatio?.y ?? 0) * imageSize.height,
            })
        }
    }

    return (
        <div 
            ref={containerRef}
            className={`
                group/image
                overflow-hidden relative rounded-[30px] bg-surface shrink-0
                ${editMode && "cursor-move"}
                ${loading && "bg-gradient-to-r from-surface via-surface-container-highest to-surface bg-200% animate-loading-wave"}
                ${className ?? ""}
            `}
        >
            <Clickable
                {...props}
                style={{ width, height, display: "flex", alignItems: "center", justifyContent: "center", ...style }}                
            >
                {src && (
                    <img
                        className="absolute object-cover max-w-max max-h-max"
                        ref={imageRef}
                        src={src}
                        onLoad={handleLoad}
                        onPointerDown={(e) => {
                            if (!editMode || e.target !== e.currentTarget) return;
                            e.preventDefault();
                            setImageMoving(true);
                            e.currentTarget.setPointerCapture(e.pointerId);
                        }}
                        onPointerUp={() => {
                            if (offset.x > 0 || offset.y > 0 || offset.x < width - imageSize.width || offset.y < height - imageSize.height) {
                                // Offset should be reset to the maximum allowed value if it's out of bounds so that it doesn't affect next movement
                                setOffset((offset) => ({
                                    x: Math.max(Math.min(0, offset.x), width - imageSize.width),
                                    y: Math.max(Math.min(0, offset.y), height - imageSize.height),
                                }));
                            }
                            setImageMoving(false)
                        }}
                        onPointerMove={(e) => {
                            if (!imageMoving || !editMode || !imageRef.current) return;
                            setOffset((offset) => ({
                                x: offset.x + e.movementX,
                                y: offset.y + e.movementY,
                            }));
                        }}
                        style={{
                            top: `${displayOffset.y}px`,
                            left: `${displayOffset.x}px`,
                            width: `${imageSize.width}px`,
                            height: `${imageSize.height}px`,
                            visibility: imageSize.width !== 0 ? undefined : "hidden",
                        }}
                    />
                )}
                {src === null && <span>Nincs kép</span>}
                {(imageHolder?.has_image || image) && editable && (
                    <div className="hidden absolute top-2 right-2 flex-col gap-1 group-hover/image:flex">
                        <button
                            className={`
                            flex items-center justify-center rounded-full p-1 cursor-pointer size-8 shadow-md
                            bg-surface fill-on-surface
                            hover:interactive-bg-error-container hover:fill-on-error-container
                        `}
                            onClick={onDelete}
                            type="button"
                        >
                            <DeleteIcon className="h-5" />
                        </button>
                        <button
                            className={`
                            flex items-center justify-center rounded-full p-1 cursor-pointer size-8 shadow-md
                            bg-surface fill-on-surface
                            hover:interactive-bg-primary-container hover:fill-on-primary-container
                        `}
                            onClick={handleToggleEditMode}
                            style={{
                                display: editMode ? "flex" : undefined,
                            }}
                            type="button"
                        >
                            {editMode ? <EditOffIcon className="h-5" /> : <EditIcon className="h-5" />}
                        </button>
                        {editMode && <button
                            className={`
                            flex items-center justify-center rounded-full p-1 cursor-pointer size-8 shadow-md
                            interactive-bg-tertiary-container !fill-on-tertiary-container
                        `}
                            onClick={handleSave}
                            style={{
                                display: editMode ? "flex" : undefined,
                            }}
                            type="button"
                        >
                            {saveLoading ? <Loader className="h-5 !fill-on-tertiary-container" /> : <SaveIcon className="h-5" />}
                        </button>}
                    </div>
                )}
                {uploadProgress && (
                    <div className="absolute bottom-0 left-0 h-1 bg-primary" style={{ width: `${uploadProgress}%` }} />
                )}
            </Clickable>
        </div>
    )
}