import { Row } from "@enymo/react-layout";
import React from "react";
import Loader from "./Loader";

export default function TableLoadingPlaceholder({
    colSpan = 3,
}: {
    colSpan?: number
}) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <Row gap="10px">
                    <Loader className="w-5" />
                    <span>Adatok betöltése...</span>
                </Row>
            </td>
        </tr>
    )
}