import { createRequiredContext } from "@enymo/react-better-context";
import { ResourceProvider } from "@enymo/react-resource-hook";
import axios from "axios";
import React, { useState } from "react";
import { route } from "ziggy-js";


const [Provider, useApp] = createRequiredContext<{
    jwt: string | null,
    setJwt: (jwt: string | null) => void
}>("AppProvider must be present in the component tree");

export { useApp };
export default function AppProvider({children}: {
    children: React.ReactNode
}) {
    const [jwt, setJwt] = useState<string | null>(null);

    // const socket = useMemo(() => io(import.meta.env.VITE_SOCKET_PUBLIC_URL, {
    //     auth: jwt !== null ? {
    //         token: jwt
    //     } : undefined
    // }), [jwt]);

    return (
        // <SocketProvider value={socket}>
            <ResourceProvider value={{ axios, routeFunction: route }}>
                <Provider value={{ jwt, setJwt }}>
                    {children}
                </Provider>
            </ResourceProvider>
        // </SocketProvider>
    )
}