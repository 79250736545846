import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import React, { useMemo } from "react";
import { useLocation } from "react-router";

export default function NavbarButton({
    children,
    className,
    to,
    ...props
}: ClickableProps) {
    const location = useLocation();
    const active = useMemo(() => to && location.pathname.startsWith(to), [location.pathname, to])
    return (
        <Clickable to={to} className={`
            ${active ? "interactive-bg-secondary-container" : "interactive-bg-surface"} 
            px-2 py-[2px] rounded-md
            ${className ?? ""}
        `} {...props}>
            {children}
        </Clickable>
    )
}