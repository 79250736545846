import React, { useMemo } from "react";
import { useParams } from "react-router";
import useMeasuring from "../Hooks/MeasuringHook";
import { Ambassador } from "../resources";
import AmbassadorCard from "./AmbassadorCard";
import Loader from "./Loader";

export default function AmbassadorCards({
    ambassadors: ambassadorsProp,
    loading,
    isPublic = false,
}: {
    ambassadors: Ambassador[],
    loading: boolean,
    isPublic?: boolean,
}) {
    const ambassadors = useMemo(() => ambassadorsProp.sort((a, b) => (b.end_date?.getTime() ?? 0) - (a.end_date?.getTime() ?? 0)), [ambassadorsProp]);
    const { campaignId, organizationId } = useParams();

    const [ref, { width }] = useMeasuring<HTMLDivElement>();

    const cardWidth = useMemo(() => {
        if (width === 0) return 0;
        for (var i = 5; i > 1; i--) {
            const option = (width - (i - 1) * 8.5) / i;

            if (option >= 220) {
                return option;
            }
        }
        return width;
    }, [width]);

    return (
        <div ref={ref} className="flex flex-wrap gap-2">
            {loading ? (
                <div className="flex flex-col items-center justify-center gap-3 flex-1">
                    <span style={{
                        color: "var(--on-surface)",
                        fontSize: "16px",
                    }}>Nagykövetek betöltése...</span>
                    <Loader className="w-12" />
                </div>
            ) : ambassadors.map((ambassador) => (
                <AmbassadorCard
                    key={ambassador.id}
                    ambassador={ambassador}
                    width={cardWidth}
                    to={`/${isPublic ? `public/organizations/${organizationId}` : "app"}/campaigns/${campaignId}/ambassadors/${ambassador.id}`}
                />
            ))}
        </div>
    )
}