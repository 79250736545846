import DonationsTable from "@/js/Components/DonationsTable";
import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import { Image } from "@/js/Components/Image";
import Loader from "@/js/Components/Loader";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import { useAlert } from "@/js/Layouts/Public";
import { useAmbassadorProps } from "@/js/Providers/Public/AmbassadorProvider";
import { useCampaign } from "@/js/Providers/Public/CampaignProvider";
import { AMBASSADOR_IMAGE_ASPECT_RATIO, EmailRegex, stripePromise, tailwindToHex, toCurrency } from "@/js/common";
import { Checkbox } from "@/js/glidespec";
import { usePublicAmbassadorDonations } from "@/js/resources";
import { Form } from "@enymo/react-form-component";
import useScreenSize from "@enymo/react-screen-size-hook";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import axios from "axios";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { route } from "ziggy-js";

interface Submit {
    donor_name: string;
    donor_email: string;
    amount: number;
    note: string;
    hide_amount: boolean;
    cover_costs: boolean;
    anonym: boolean;
}

export default function Ambassador() {
    const { organizationId, campaignId, ambassadorId } = useParams();
    const navigate = useNavigate();
    const {
        donationSuccessful: [, setDonationSuccess],
        ambassador: [ambassador]
    } = useAmbassadorProps();

    const [donations] = usePublicAmbassadorDonations({
        params: useMemo(() => ({
            ambassador: ambassadorId
        }), [ambassadorId]),
    });

    const [campaign] = useCampaign();

    const { width } = useScreenSize();
    const mobile = width < 700;

    const imageWidth = useMemo(() => mobile ? width - 2 * 20 : 250, [mobile, width])

    const form = useForm<Submit>({
        mode: "all",
        defaultValues: {
            hide_amount: false,
        }
    });
    const amount = form.watch("amount");
    const name = form.watch("donor_name");
    const coverCosts = form.watch("cover_costs");

    const totalAmount = useMemo(() => amount ? Math.floor(coverCosts ? amount * 1.015 + 85 : amount) : undefined, [coverCosts, amount]);
    const handleSubmit = (elements: StripeElements | undefined, stripe: Stripe | null) => async (data: Submit) => {
        if ((await elements?.submit())?.error !== undefined) {
            return;
        }

        const clientSecret = (await axios.post<string>(route("public.ambassadors.donate", {
            ambassador: ambassadorId,
        }), {
            ...data,
            amount: totalAmount,
        })).data;

        await stripe?.confirmPayment?.({
            elements,
            clientSecret,
            redirect: "if_required",
            confirmParams: {
                return_url: `${window.location.href}?redirected=true`,
            }
        })
        setDonationSuccess("success");
        navigate(`/public/organizations/${organizationId}/campaigns/${campaignId}/ambassadors/${ambassadorId}/donation-successful`)
    }

    const styles = getComputedStyle(document.body);

    useAlert(((campaign?.active ?? true) && (ambassador?.end_date ? (ambassador.end_date < new Date()) : false)) ? {
        type: "error",
        title: "A nagykövet kampánya lejárt!",
        message: "Sajnos ez a kampány már lezárult, így nem tudsz adományozni."
    } : null);

    return (
        <div className="flex flex-col flex-1 pt-5 pb-12 max-w-[1200px] self-center w-full">
            {ambassador ? (
                <div className="flex flex-col gap-10 flex-1">
                    <div className="flex flex-col sm:flex-row gap-10">
                        <Image
                            imageHolder={{ ...ambassador, source: "ambassadors" }}
                            aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                            className="w-full sm:w-[250px]"
                        />
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-col gap-1">
                                <h1 className="hd-s text-primary">{ambassador?.name}</h1>
                                <h2 className="ttl-m">
                                    Kampány: <Link
                                        to={`/public/organizations/${organizationId}/campaigns/${campaignId}`} 
                                        className="interactive-bg-secondary-container p-1 rounded-md">
                                            {campaign?.name}
                                        </Link>
                                </h2>
                            </div>
                            <span style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>{ambassador?.info}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l text-primary">Eddigi adományok</h2>
                        <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between gap-3">
                                    <span>Összesen:</span>
                                    <span>{toCurrency(ambassador.donation_amount)}</span>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <span>Célösszeg:</span>
                                    <span>{toCurrency(ambassador.donation_goal)}</span>
                                </div>
                            </div>
                            <div className="flex flex-1">
                                <StaticProgressBar progress={ambassador.donation_amount / ambassador.donation_goal * 100} variant="primary" />
                            </div>
                        </div>
                        {donations.length > 0 && <DonationsTable donations={donations} maxEntries={5} />}
                    </div>
                    {(campaign?.active && (!ambassador.end_date || ambassador.end_date >= new Date())) && (
                        <div className="flex flex-col gap-5">
                            <h2 className="ttl-l text-primary">Adományozás</h2>
                            <Elements stripe={stripePromise} options={{
                                mode: "payment",
                                amount: (totalAmount && totalAmount >= 500 ? totalAmount : 500) * 100,
                                currency: "huf",
                                locale: "hu",
                                appearance: {
                                    theme: "flat",
                                    variables: {
                                        fontFamily: "Roboto, sans-serif",
                                        colorPrimary: tailwindToHex(styles.getPropertyValue("--color-primary")),
                                        colorBackground: tailwindToHex(styles.getPropertyValue("--color-surface")),
                                        colorDanger: tailwindToHex(styles.getPropertyValue("--color-error")),
                                        colorText: tailwindToHex(styles.getPropertyValue("--color-on-surface-variant")),
                                        borderRadius: "12px",

                                    },
                                    rules: {
                                        '.Input': {
                                            height: "50px",
                                            border: `1.5px solid ${tailwindToHex(styles.getPropertyValue("--color-outline"))}`,
                                            fontSize: "15px",
                                        },
                                        '.Input--invalid': {
                                            border: 'none',
                                        },
                                        '.Input:focus': {
                                            borderColor: tailwindToHex(styles.getPropertyValue("--color-primary")),
                                            boxShadow: "none",
                                        },
                                        '.Input:hover': {
                                            borderColor: tailwindToHex(styles.getPropertyValue("--color-on-surface")),
                                        },
                                        '.Label': {
                                            fontWeight: "500",
                                            fontSize: "15px",
                                            color: tailwindToHex(styles.getPropertyValue("--color-on-surface-variant")),
                                            marginBottom: "7px",
                                        },
                                        '.AccordionItem': {
                                            backgroundColor: tailwindToHex(styles.getPropertyValue("--color-surface-container")),
                                            border: `1px solid ${tailwindToHex(styles.getPropertyValue("--color-outline"))}`,
                                            padding: "15px",
                                        },
                                    },
                                },
                            }}>
                                <ElementsConsumer>
                                    {({ elements, stripe }) => (
                                        <Form form={form} onSubmit={handleSubmit(elements ?? undefined, stripe)} className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-1">
                                                <Input name="donor_name" label="Név" />
                                                <p className="bd-s ml-1">
                                                    A név megadása nem kötelező. Ha megadod, akkor a kampány szervezői látni fogják, hogy te adományoztál.
                                                </p>
                                            </div>
                                            {name && (
                                                <div className="flex flex-col gap-1">
                                                    <Checkbox name="anonym">
                                                        Anonim adományozás
                                                    </Checkbox>
                                                    <p className="bd-s ml-1">
                                                        A kampány szervezői továbbra is látni fogják a neved, de nyilvánosan nem jelenik meg.
                                                    </p>
                                                </div>
                                            )}
                                            <div className="flex flex-col gap-1">
                                                <Input name="donor_email" label="Email" options={{
                                                    pattern: {
                                                        value: EmailRegex,
                                                        message: "Adj meg egy érvényes e-mail címet!"
                                                    },
                                                }} />
                                                <p className="bd-s ml-1">
                                                    Az e-mail megadása nem kötelező. Add meg, ha szeretnéd, hogy a kampány szervezői kapcsolatba léphessenek veled.
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <Input name="note" label="Megjegyzés" />
                                                <p className="bd-s ml-1">
                                                    Ha szeretnél valamit üzenni a nagykövetnek, akkor itt megteheted. Ez a megjegyzés nyilvánosan is látható lesz.
                                                </p>
                                            </div>
                                            <Input name="amount" label="Összeg" type="number" suffix="Ft" options={{
                                                required: "Kérjük add meg az összeget!",
                                                validate: (value) => value >= 500 || "Minimum 500 Ft-tal támogathatod a kampányt!",
                                            }} />
                                            {amount && amount >= 500 && (
                                                <div className="flex flex-col gap-4">
                                                    <div className="flex flex-col gap-1">
                                                        <Checkbox name="hide_amount">
                                                            Összeg elrejtése
                                                        </Checkbox>
                                                        <p className="bd-s ml-1">
                                                            A kampány szervezői továbbra is látni fogják az összeget, de nyilvánosan nem jelenik meg.
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-col gap-1">
                                                        <Checkbox name="cover_costs">
                                                            Kifizetem a kezelési költségeket
                                                        </Checkbox>
                                                        <p className="bd-s ml-1">
                                                            A fizetések kezelését egy harmadik fél végzi, mely kezelési költésgeket számol fel. Ez ennél a tranzakciónál <span style={{
                                                                fontWeight: "500"
                                                            }}>{toCurrency(amount * 0.015 + 85)}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {totalAmount && totalAmount >= 500 && (
                                                <div style={{
                                                    margin: "20px 0",
                                                }}>
                                                    <PaymentElement options={{
                                                        layout: "accordion",
                                                    }} />
                                                </div>
                                            )}
                                            <div className="flex justify-center">
                                                <Button variant="filled" disabled={!(totalAmount && totalAmount >= 500)} submit>Adományozás</Button>
                                            </div>
                                        </Form>
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-col flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            )}
        </div>
    )
}