import React from "react";
import FileDropzone from "./FileDropzone";
import { Image, PositionRatio } from "./Image";

interface CommonProps {
    uploadProgress?: number,
    handleFileSelect: (files: File[]) => void,
    positionRatio?: PositionRatio,
    onEdit?: (postitionRatio: PositionRatio) => void | Promise<void>,
    onDelete: () => void,
    aspectRatio: number,
    axisSet?: "x" | "y",
    className?: string,
}

interface ImageInputProps extends CommonProps {
    image: File | null,
}

interface ResourceImageInputProps extends CommonProps {
    imageHolder: {
        id: number,
        has_image: boolean,
        file?: File | null,
        source: string,
        image_position_ratio: PositionRatio | null,
    },
}

interface CombinedImageInputProps extends CommonProps {
    imageHolder?: {
        id: number,
        has_image: boolean,
        file?: File | null,
        source: string,
        image_position_ratio: PositionRatio | null,
    },
    image?: File | null,
}

export function ImageInput(props: ResourceImageInputProps): JSX.Element;
export function ImageInput(props: ImageInputProps): JSX.Element;

export function ImageInput({
    image,
    imageHolder,
    handleFileSelect,
    aspectRatio,
    axisSet,
    className,
    ...props
}: CombinedImageInputProps) {
    return (
        <div style={{ flexShrink: "0" }}>
            {(imageHolder?.has_image || imageHolder?.file || image) ? (
                imageHolder ? (
                    <Image imageHolder={imageHolder!} aspectRatio={aspectRatio} axisSet={axisSet} className={className} editable {...props} />
                ) : (
                    <Image image={image ?? null} aspectRatio={aspectRatio} axisSet={axisSet} className={className} editable {...props} />
                )
            ) : (
                <FileDropzone
                    onSelectFile={handleFileSelect}
                    className={className}
                    aspectRatio={aspectRatio}
                    axisSet={axisSet}
                />
            )}
        </div>
    )
}