import "@/css/components/tooltip.scss";
import { useHovering } from "@/js/Hooks/HoveringHook";
import { createRequiredContext } from "@enymo/react-better-context";
import useScreenSize from "@enymo/react-screen-size-hook";
import { isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo, useRef } from "react";

const [Provider, useTooltipParent] = createRequiredContext<{
    show: boolean,
    parentRect?: DOMRect,
}>("The Tooltip component must be wrapped in a TooltipParent component.");

export default function Tooltip({
    children,
    preferredPosition = "top",
}: {
    children?: React.ReactNode,
    preferredPosition?: "top" | "bottom",
}) {
    const ref = useRef<HTMLDivElement>(null);
    const { show, parentRect } = useTooltipParent();
    const { width: screenWidth, height: screenHeight } = useScreenSize();

    const style = useMemo<React.CSSProperties | undefined>(() => {
        if (isNotNull(ref.current) && isNotNull(parentRect)) {
            const { width, height } = ref.current.getBoundingClientRect();
            const padding = 10;

            let showPosition = preferredPosition;
            if (showPosition === "top" && parentRect.y - height - 3 < padding) {
                showPosition = "bottom";
            }
            else if (showPosition === "bottom" && parentRect.y + parentRect.height + height + 3 > screenHeight - padding) {
                showPosition = "top";
            }
            if (showPosition === "top") {
                return {
                    top: `${parentRect.y - height - 7}px`,
                    left: `${Math.min(
                        Math.max(parentRect.x + parentRect.width / 2 - width / 2, padding),
                        screenWidth - width - padding
                    )}px`,
                }
            }
            else {
                return {
                    top: `${parentRect.y + parentRect.height + 9}px`,
                    left: `${Math.min(
                        Math.max(parentRect.x + parentRect.width / 2 - width / 2, padding),
                        screenWidth - width - padding
                    )}px`,
                }
            }
        }
        return undefined;
    }, [ref, show, parentRect, screenWidth, screenHeight]);

    return (
        <div ref={ref} className={classNames("tooltip")} style={{
            ...style,
            visibility: show ? "visible" : "hidden",
        }}>
            <span className="text">{children}</span>
        </div>
    )
}

export function TooltipParent({
    className,
    children,
    disabled = false,
    style,
}: {
    className?: string,
    children: React.ReactNode,
    disabled?: boolean,
    style?: React.CSSProperties,
}) {

    const [ref, hover] = useHovering<HTMLDivElement>();
    const show = hover && !disabled;

    return (
        <div ref={ref} className={classNames(className, "tooltip-parent", { disabled })} style={style}>
            <Provider value={{ show, parentRect: ref.current?.getBoundingClientRect() }}>
                {children}
            </Provider>
        </div>
    )
}