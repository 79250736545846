import { Image } from "@/js/Components/Image";
import { useAmbassadorProps } from "@/js/Providers/Public/AmbassadorProvider";
import { AMBASSADOR_IMAGE_ASPECT_RATIO } from "@/js/common";
import React from "react";
import { Navigate, useParams } from "react-router";

export default function DonationSuccessful() {
    const {
        donationSuccessful: [donationSuccess],
        ambassador: [ambassador]
    } = useAmbassadorProps();
    const { organizationId, campaignId, ambassadorId } = useParams();

    if (donationSuccess !== "success") {
        return <Navigate to={`/public/organizations/${organizationId}/campaigns/${campaignId}/ambassadors/${ambassadorId}`} />
    }

    return (
        <div className="flex flex-col w-full h-full gap-12 justify-center items-center p-4 sm: p-4">
            {ambassador && (
                <Image
                    imageHolder={{ ...ambassador, source: "ambassadors" }}
                    className="w-full sm:w-[300px]"
                    aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                />
            )}
            <div className="flex flex-col gap-5 items-center text-center">
                <h1 className="hd-l">Köszönjük szépen!</h1>
                <span className="bd-l">
                    Az adományodat sikeresen megkaptuk. Köszönjük, hogy támogatod a kampányt!
                </span>
            </div>
        </div>
    )
}