import { useGlissadeButton } from "@enymo/glissade";
import React from "react";
import Loader from "../Loader";


export default function Button({
    submit = false,
    loading: loadingProp,
    disabled: disabledProp,
    onClick: onClickProp,
    children,
    variant = "filled",
    className,
}: {
    submit?: boolean,
    loading?: boolean,
    disabled?: boolean,
    onClick?: React.MouseEventHandler,
    children?: React.ReactNode,
    variant?: "outline" | "filled" | "filled-tonal" | "text" | "elevated",
    className?: string,
}) {
    const { disabled, loading, onClick } = useGlissadeButton({ 
        submit, 
        loading: loadingProp, 
        disabled: disabledProp, 
        onClick: onClickProp
    });

    return (
        <button
            type={submit ? "submit" : "button"}
            disabled={disabled}
            onClick={onClick} 
            className={`${className ?? ""} 
                relative flex items-center font-noto font-medium h-[40px] px-[24px] rounded-[20px] shrink-0
                ${variant === "filled" && "interactive-bg-primary"}
                ${variant === "outline" && "border-outline border border-solid interactive-bg-surface"}
                ${variant === "filled-tonal" && "interactive-bg-secondary-container"}
            `}
        >
            <div className={loading ? "invisible" : ""}>{children}</div>
            {loading && (
                <Loader className={`
                    absolute h-[20px] left-[calc(50%-10px)] top-[calc(50%-10px)]
                    ${variant === "filled" && "!fill-on-primary"}
                    ${variant === "outline" && "!fill-on-surface"}    
                `} />
            )}
        </button>
    )
}