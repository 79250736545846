import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useMeasuring from "../Hooks/MeasuringHook";
import CutText from "./CutText";
import Input, { InputProps } from "./Form/Input";

export default function PropertyForm({
    editable,
    properties,
}: {
    editable: boolean;
    properties: {
        label: string;
        value: string | number | undefined;
        cutLength?: number;
        input?: Omit<InputProps, "label"> & {
            label?: string | "{useLabel}"
        };
    }[];
}) {
    const [properyLabelWidths, setProperyLabelWidths] = useState<{
        [key: string]: number;
    }>({});

    const [ref, { width }] = useMeasuring<HTMLDivElement>();

    const handleSetWidth = useCallback((label: string, width: number) => {
        setProperyLabelWidths((prev) => ({ ...prev, [label]: width }));
    }, [setProperyLabelWidths]);

    const labelWidth = useMemo(() => Math.max(...Object.values(properyLabelWidths)), [properyLabelWidths]);

    return (
        <div ref={ref} className="flex flex-col justify-center gap-5">
            {editable ? (
                <>
                    {properties.flatMap(({ label, input }) => input ? [
                        <Input
                            key={input.name}
                            {...input}
                            label={input.label === "{useLabel}" ? label : input.label}
                        />
                    ] : [])}
                </>
            ) : (
                <>
                    {properties.map((property, index) => (
                        <ProperyRow
                            key={index}
                            mobile={width !== 0 && width < 500}
                            label={`${index}. ${property.label}`}
                            value={property.value}
                            cutLength={property.cutLength}
                            setWidth={handleSetWidth}
                            width={labelWidth}
                        />
                    ))}
                </>
            )}
        </div>
    );
}


function ProperyRow({ label: labelProp, value, cutLength, setWidth, width, mobile = false }: {
    label: string;
    value: string | number | undefined;
    cutLength?: number;
    setWidth: (label: string, width: number) => void;
    width: number;
    mobile?: boolean;
}) {
    const label = useMemo(() => labelProp.split(".").slice(1).join("."), [labelProp]);
    const labelRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (labelRef.current) {
                labelRef.current.style.width = ""; // Set to empty string to get the actual width
                setWidth(labelProp, labelRef.current.clientWidth);
                labelRef.current.style.width = `${width}px`;
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [labelRef, setWidth]);

    return (
        <div className={`flex gap-10 justify-between ${mobile && "flex-col gap-2 justify-normal"} bd-l`}>
            <h2 ref={labelRef} style={{
                width: width !== -Infinity ? `${width}px` : undefined,
            }}>{label}</h2>
            {cutLength === undefined ? (
                <span>{value ?? "-"}</span>
            ) : (
                <CutText maxLength={cutLength} text={value?.toString() ?? "-"} />
            )}
        </div>
    );
}