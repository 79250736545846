import React from "react";
import App from "./App";
import { PopupParentProvider } from "./Components/Popup";
import AppProvider from "./Providers/AppProvider";

export default function Bootstrapper() {
    return (
        <AppProvider>
            <PopupParentProvider>
                <App />
            </PopupParentProvider>
        </AppProvider>
    )
}