import React from "react";
import { useParams } from "react-router";
import { CampaignAmbassadorsProvider as ResoureCampaignAmbassadorsProvider } from "../../resources";

export default function CampaignAmbassadorsProvider({ children }: {
    children: React.ReactNode,
}) {
    const { campaignId } = useParams();
    return (
        <ResoureCampaignAmbassadorsProvider params={{campaign: Number(campaignId)}}>
            {children}
        </ResoureCampaignAmbassadorsProvider>
    );
}