import { loadStripe } from "@stripe/stripe-js";

export const languages = ["hu", "en"] as const;
export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function toCurrency(amount: number) {
    return amount.toLocaleString("hu-HU", {
        style: "currency",
        currency: "HUF",
        maximumFractionDigits: 0,
    });
}

export function addOpacity(color: string, opacity: number) {
    return `rgb(from var(--${color}) r g b / ${opacity});`
}

export function mixColors(color1: string, color2: string, percentage: number, semiColon: boolean = true) {
    return `color-mix(in srgb, var(--${color1}), var(--${color2}) ${percentage}%)${semiColon ? ";" : ""}`;
}

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);

export const CAMPAIGN_IMAGE_ASPECT_RATIO = 2;
export const AMBASSADOR_IMAGE_ASPECT_RATIO = 1;
export const ORGANIZATION_IMAGE_ASPECT_RATIO = 2.2;

export function tailwindToHex(color: string) {
    const [r, g, b] = color.split(" ").map(x => parseInt(x))
    return "#" + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? "0" + hex : hex
    }).join("")
}