import { User, UserUpdate, useUsers } from "@/js/resources";
import { createContext } from "@enymo/react-better-context";
import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { DeepPartial } from "ts-essentials";
import { route } from "ziggy-js";
import { useApp } from "./AppProvider";


type Login = (email: string, password: string, remember: boolean, resetToken?: string) => Promise<void>
type Update = (update: DeepPartial<UserUpdate>, updateMethod?: "on-success" | "immediate" | "local-only") => Promise<void>
type Delete = (password?: string) => Promise<void>
type Logout = () => Promise<void>
type Refresh = () => Promise<void>

const [Provider, useUser] = createContext<{
    user: User | null,
    login: Login,
    update: Update,
    deleteAccount: Delete,
    logout: Logout,
    refresh: Refresh,
    loading: boolean
} | null>(null);
export { useUser };

export default function UserProvider({children}: {
    children?: React.ReactNode
}) {
    const {t, i18n} = useTranslation();
    const { setJwt } = useApp();
    const [user, {store, update, destroy, refresh, loading}] = useUsers({
        id: "me"
    });

    const login = useCallback<Login>(async (email, password, remember, resetToken) => {
        const response = await (resetToken === undefined
            ? axios.post(route("users.login"), {email, password, remember}) 
            : axios.post(route("users.reset-password", {email, password, remember, token: resetToken})));
        await update(response.data, "local-only");
    }, [update, axios]);

    const deleteAccount = useCallback<Delete>(async password => {
        await destroy(undefined, password ? {
            headers: {
                Authorization: `Password ${password}`
            }
        } : undefined);
    }, [axios]);

    const logout = useCallback<Logout>(async () => {
        await axios.post(route("users.logout"));
        await destroy("local-only");
    }, [destroy]);

    useEffect(() => {
        setJwt(user?.jwt ?? null);
    }, [user]);

    useEffect(() => {
        if (user !== null && user.language !== i18n.language) {
            i18n.changeLanguage(user.language);
        }
    }, [user?.language, i18n]);

    return (
        <Provider value={{
            user,
            login,
            update,
            deleteAccount,
            logout,
            refresh,
            loading
        }}>
            {children ?? <Outlet />}
        </Provider>
    )
}